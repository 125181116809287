
import { Model, Attribute, BelongsTo } from './decorators';
import { BaseApiModel } from './base';
import { Target } from './target.model';
import { TargetVersionType, TargetType } from '@parashift/shared/types';

export interface TargetVersionPlainModel {
  id: string;
  target_id: number;
  url: string;
  version: string;

  target: (Target['plainModel'] | Partial<Target['plainModel']>)[];
}

@Model({ type: TargetVersionType })
export class TargetVersion extends BaseApiModel<TargetVersionPlainModel> {
  @Attribute()
  target_id: number;

  @Attribute()
  url: string;

  @Attribute()
  version: string;

  @BelongsTo({ class: TargetType })
  target: Target;
}
